html {
  background-color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* TODO: Check if this messes up */
  overflow: hidden;

  /* .5s -> iOS keyboard opening duration */
  /* transition: height .35s; */
  transition: height .1s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  /* height: 100vh; */
  transition: height 1s;
  height: 100%;

  /* noinspection CssUnresolvedCustomProperty */
  /* height: calc(var(--vh, 1vh) * 100); */
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;

}

*::-webkit-scrollbar {
  display: none;
}

html, body {
  touch-action: none !important;
}
